<template>
  <div
    class="border-opacity-25 pt-6 pb-6 w-full top-0 border-b-2 border-gray-300 bg-gray-100"
  >
    <div
      class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-2xl inline-block hidden lg:block pl-2 pr-2"
    >
      <div class="grid grid-cols-1 lg:grid-cols-5 md:grid-cols-5">
        <div class="inline-block flex">
          <a
            href="/"
            class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded cursor-pointer mr-2"
          >
            <span
              class="leading-tight whitespace-nowrap text-2xl text-gray-200"
            >
              <img
                class="h-9 inline"
                src="https://cdn.discordapp.com/attachments/1080058777971212370/1126416096895979521/Header-logo.png"
              />
            </span>
          </a>
        </div>

        <div class="col-span-3 text-center">
          <!-- <div
            class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl  text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-blue-900 cursor-pointer"
          >
            Device
          </div>
          <div
            class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl  text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-blue-900 cursor-pointer"
          >
            Prints
          </div>
          <div
            class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl  text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-blue-900 cursor-pointer"
          >
            Co-Lab
          </div> -->

          <router-link
            to="/custom-cases/photo-design"
            class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-blue-900 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 font-sans font-extrabold"
          >
            Customization
          </router-link>

          <router-link
            to="/creativeAI"
            class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-blue-900 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 font-sans font-extrabold"
          >
            AI Creative
          </router-link>
          <router-link
            to="/product"
            class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-blue-900 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 font-sans font-extrabold"
          >
            Products
          </router-link>
          <router-link
            to="/new"
            class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-blue-900 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 font-sans font-extrabold"
          >
            News & Featured
          </router-link>
          <router-link
            to="/OEM"
            class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-blue-900 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 font-sans font-extrabold"
          >
            OEM
          </router-link>
          <router-link
            to="/contact"
            class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-blue-900 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 font-sans font-extrabold"
          >
            Contact
          </router-link>
        </div>

        <div class="flex-grow text-right">
          <div class="">
            <div
              class="inline-block lg:hidden sm:block"
              v-on:click="menuMobile()"
            >
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white bg-gray-800 cursor-pointer mr-2 mt-2"
              >
                <i class="fas fa-align-justify" style="font-size: 20px"></i>
              </div>
            </div>
          </div>

          <div class="inline-block hidden lg:block">
            <div
              class="inline-block mt-2 ml-3 p-px cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
              @click="linkLogin('account')"
            >
              <i class="far fa-user-circle" style="font-size: 24px"></i>
            </div>
            <!-- <div
              class="inline-block mt-2 ml-3 p-px cursor-pointer"
              @click="linkLogin('account')"
            >
              <i class="fa fa-search" style="font-size: 24px"></i>
            </div> -->
            <div
              class="inline-block mt-2 ml-3 p-px cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
              @click="linkLogin('order')"
            >
              <i class="fas fa-cart-arrow-down" style="font-size: 24px"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-2xl inline-block lg:hidden sm:block"
    >
      <nav class="flex bg-opacity-0">
        <div class="flex-none">
          <div class="inline-block flex">
            <a
              href="/"
              class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded cursor-pointer mr-2"
            >
              <span
                class="leading-tight whitespace-nowrap text-2xl text-gray-200"
              >
                <img
                  class="h-9 inline"
                  src="https://cdn.discordapp.com/attachments/1080058777971212370/1126416096895979521/Header-logo.png"
                />
              </span>

              {{ getMenu }}
            </a>
          </div>
        </div>
        <div class="flex-grow text-right">
          <div class="">
            <div
              class="inline-block lg:hidden sm:block"
              v-on:click="menuMobile()"
            >
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-gray-500 cursor-pointer mr-2 mt-2"
              >
                <i class="fas fa-align-justify" style="font-size: 20px"></i>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <div class="lg:hidden lg:block h-screen fixed w-full" v-if="show">
      <!-- <div class="flex-grow text-center">
        <div class="mt-3">
          <router-link to="/" class="inline-block">
            <div
              class="inline-block mt-2 p-px bg-gradient-to-r from-pink-700 to-yellow-500 rounded-2xl"
            >
              <div
                v-scroll-to="'#OVERVIEW'"
                class="inline-block text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer"
              >
                OVERVIEW
              </div>
            </div>
          </router-link>
        </div>
      </div> -->

      <div class="flex justify-center items-center h-full">
        <div>
          <div
            class="grid grid-cols-1 gap-2 lg:grid-cols-1 md:grid-cols-1 -mt-48 text-center"
          >
            <router-link to="/" class="p-5">
              <h3
                class="max-w-lg mb-6 font-sans text-3xl sm:text-7xl font-bold leading-none tracking-tight md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-gray-500 hover:text-white cursor-pointer"
              >
                Home
              </h3>
            </router-link>
            <router-link to="/custom-cases/photo-design" class="p-5">
              <h3
                class="max-w-lg mb-6 font-sans text-3xl sm:text-7xl font-bold leading-none tracking-tight md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-gray-500 hover:text-white cursor-pointer"
              >
                Customization
              </h3>
            </router-link>
            <router-link to="/creativeAI" class="p-5">
              <h2
                class="max-w-lg mb-6 font-sans text-3xl sm:text-7xl font-bold leading-none tracking-tight md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-gray-500 hover:text-white cursor-pointer"
              >
                AI Creative
              </h2>
            </router-link>

            <router-link to="/product" class="p-5">
              <h2
                class="max-w-lg mb-6 font-sans text-3xl sm:text-7xl font-bold leading-none tracking-tight md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-gray-500 hover:text-white cursor-pointer"
              >
                Products
              </h2>
            </router-link>

            <router-link to="/new" class="p-5">
              <h2
                class="max-w-lg mb-6 font-sans text-3xl sm:text-7xl font-bold leading-none tracking-tight md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-gray-500 hover:text-white cursor-pointer"
              >
                New & Featured
              </h2>
            </router-link>

            <div to="/contact" class="p-5">
              <h2
                class="max-w-lg mb-6 font-sans text-3xl sm:text-7xl font-bold leading-none tracking-tight md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-gray-800 via-blue-900 to-gray-500 hover:text-white cursor-pointer"
              >
                Contact Us
              </h2>
            </div>

            <div class="p-5">
              <div
                class="inline-block mt-2 ml-3 p-px"
                @click="linkLogin('account')"
              >
                <i class="far fa-user-circle" style="font-size: 54px"></i>
              </div>
              <div
                class="inline-block mt-2 ml-3 p-px"
                @click="linkLogin('account')"
              >
                <i class="fa fa-search" style="font-size: 54px"></i>
              </div>
              <div
                class="inline-block mt-2 ml-3 p-px"
                @click="linkLogin('order')"
              >
                <i class="fas fa-cart-arrow-down" style="font-size: 54px"></i>
              </div>
            </div>
          </div>

          <div
            class="flex justify-center mt-16 cursor-pointer animate-bounce"
            @click="menuMobile()"
          >
            <span class="h-20 w-20 flex justify-center items-center">
              <span
                class="animate-ping absolute inline-flex h-20 w-20 rounded-full bg-blue-500 hover:bg-red-400 opacity-75"
              ></span>
              <div
                class="relative inline-flex rounded-full h-20 w-20 bg-purple-600 hover:bg-red-500 flex justify-center items-center text-3xl text-gray-200"
              >
                X
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nativeToast from "native-toast";
import Web3 from "web3";
import Web3Token from "web3-token";
import { TESTNET, CHAIN, NETWORK_VERSION } from "../config";

import { mapGetters, mapActions } from "vuex";

import user from "@/api/user";

export default {
  data() {
    return {
      CHAIN: CHAIN,
      loading: false,
      token: JSON.parse(localStorage.getItem("Token")),
      networkVersion: JSON.parse(localStorage.getItem("networkVersion")),
      checkChain: false,
      show: false,
    };
  },
  computed: mapGetters(["getUser", "isConnected", "getMenu"]),

  methods: {
    ...mapActions(["setUser", "setMenu", "setToken"]),
    menuMobile() {
      if (this.show) {
        this.show = false;
        this.setMenu(false);
      } else {
        this.show = true;
        this.setMenu(true);
      }
    },
    linkLogin(link) {
      if (this.getUser) {
        this.$router.push("/user/" + link);
      } else {
        this.$router.push("/singin");
      }
    },
  },
  created() {
    this.setMenu(false);
  },
};
</script>
